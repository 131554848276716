import LocalTime from 'local-time'
LocalTime.config.i18n["es"] = {
    date: {
        dayNames: [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado",
        ],
        abbrDayNames: [
            "dom",
            "lum",
            "mar",
            "mié",
            "jue",
            "vie",
            "sáb",
        ],
        monthNames: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ],
        abbrMonthNames: [
            "ene",
            "feb",
            "mar",
            "abr",
            "may",
            "jun",
            "jul",
            "ago",
            "sep",
            "oct",
            "nov",
            "dic",
        ],
        yesterday: "ayer",
        today: "hoy",
        tomorrow: "mañana",
        on: "en {date}",
        formats: {
            default: "%d/%m/%Y",
            long: "%A, %d de %B de %Y",
            short: "%d de %b",
            highcharts: "%y-%m-%d"
        }
    },
    time: {
        am: "am",
        pm: "pm",
        singular: "en {time}",
        singularAn: "en {time}",
        elapsed: "hace {time}",
        second: "segundo",
        seconds: "segundos",
        minute: "minuto",
        minutes: "minutos",
        hour: "hora",
        hours: "horas",
        formats: {
            default: "%A, %d de %B de %Y %H:%M",
            long: "%d de %B de %Y %H:%M",
            short: "%d de %b %H:%M",
            custom: "%d/%m/%Y - %H:%M",
            file: "%d-%m-%Y__%H-%M"
        }
    },
    datetime: {
        at: "{date} - {time}",
        formats: {
            default: "%A, %d de %B de %Y %H:%M"
        }
    }
}

LocalTime.start()
export default LocalTime