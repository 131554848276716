// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start();
import * as Turbo from "@hotwired/turbo";
window.Turbo = Turbo;
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import * as bootstrap from "bootstrap";
import './menu-left.cleanui-custom'
import './menu-right.cleanui-custom'
import 'select2'
import langpl from "select2/src/js/select2/i18n/es";
global.toastr = require("toastr");
window.toastr = toastr


import '../stylesheets/application'

import LocalTime from '../src/local_time.js'
window.LocalTime = LocalTime
import "../src/pagy.js.erb";
global.$ = jQuery;

["turbo:load", "turbo:frame-load"].forEach(function (evt) {
  document.addEventListener(evt, () => {
    let popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl, {
        sanitize: false,
      });
    });

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });

    var toastElList = [].slice.call(document.querySelectorAll(".toast"));
    var toastList = toastElList.map(function (toastEl) {
      return new bootstrap.Toast(toastEl).show();
    });

    $(".js-select2-form").select2({
      theme: 'bootstrap-5',
      language: langpl,
    });
  });
});

$(document).on('shown.bs.tab', '.nav-pills > a', function (e) {
    $(this).siblings().removeClass('active');
});

$(document).on('show.bs.dropdown', '.nav-pills .dropdown', function (e) {
    $(this).siblings().find('.active').removeClass('active');
});

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});

$(document).on('click', '*[data-href]', function (e) {
    window.open($(this).data('href'), '_blank');
});

$(document).on('click', '.button-form-ranking-prices-top', function (e) {
    $.each($(".form-button-ranking-price-top"), function () {
        $(this).click();
    });
});

$(document).on('click', '.button-form-ranking-prices-old', function (e) {
    $.each($(".form-button-ranking-price-old"), function () {
        $(this).click();
    });
});

$(document).on("click", ".button-form-ranking-prices-old", function (e) {
  $.each($(".form-button-ranking-price-old"), function () {
    $(this).click();
  });
});

document.addEventListener("turbo:submit-start", function () {
  Turbo.navigator.delegate.adapter.showProgressBar();
});

$(document).on("turbo:before-cache", function () {
  $(".js-select2-form").each(function () {
    $(this).data("select2").destroy();
  });

  var toastElList = [].slice.call(document.querySelectorAll(".toast"));
  var toastList = toastElList.map(function (toastEl) {
    new bootstrap.Toast(toastEl).dispose();
  });
});